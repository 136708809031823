import React, { useEffect } from 'react'
import config from '../config.json';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import PageTitle from '../../shared/components/PageTitle'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import clsx from 'clsx';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .message{
        width:50%;
    }
`;

const ResetPassword = (match) => {
    const classes = useStyles();
    const [msg, setMsg] = React.useState('');
    const [values, setValues] = React.useState({
        Pwd: '',
        showPwd: false,
        password: '',
        showPassword: false,
        reTypePassword: '',
        showReTypePassword: false,
    });
    var [userEmail, setUserEmail] = React.useState("");
    const [pwdError, setPwdError] = React.useState("")
    const [passwordError, setPasswordError] = React.useState("")
    const [retypePasswordError, setRetypePasswordError] = React.useState("")
    var user;
    var userProfile;
    const navigate = useNavigate();


    useEffect(() => {
        userProfile = localStorage.getItem("userProfile");
        user = JSON.parse(userProfile);
        userEmail = user.userEmail;
        setUserEmail(userEmail)
    }, [])

    const handleChange = (prop) => (event) => {

        setValues({ ...values, [prop]: event.target.value }, []);
        event.preventDefault();
    };

    const handleClickShowPassword = (event) => {
        setValues({ ...values, showPassword: !values.showPassword });
        event.preventDefault();
    };

    const handleClickShowPwd = (event) => {
        setValues({ ...values, showPwd: !values.showPwd });
        event.preventDefault();
    };

    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowReTypePassword = (event) => {
        setValues({ ...values, showReTypePassword: !values.showReTypePassword });
        event.preventDefault();
    };

    const handleMouseDownReTypePassword = (event) => {
        event.preventDefault();
    };

    const submitUpdate = (event) => {
        event.preventDefault();

        if (values.password != values.reTypePassword) {
            setRetypePasswordError("Retype password correctly!")
            setTimeout(() => { setRetypePasswordError("") }, 4000)
        } else if (!values.Pwd) {
            setPwdError("Enter Current password!")
            setTimeout(() => { setPwdError("") }, 4000)
        } else if (!values.password) {
            setPasswordError("Enter New Password!")
            setTimeout(() => { setPasswordError("") }, 4000)
        } else if (!values.reTypePassword) {
            setRetypePasswordError("Retype password")
            setTimeout(() => { setRetypePasswordError("") }, 4000)
        }
        else {
            const data = {

                userEmail: userEmail,
                pwd: values.Pwd,
                newPwd: values.password
            }
            const MOBILESURETY_UPDATE_URL = config.API_URL + "/api/user/passwordreset"
            const token = config.TOKEN
            axios.post(MOBILESURETY_UPDATE_URL, data, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {

                    if (res.data.successCode === -1) {
                        setMsg(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "Invalid Credentials"
                                </div>
                            </div>)
                        setTimeout(() => { setMsg("") }, 5000)
                    }
                    else {
                        setMsg(
                            <div className="message">
                                <div className="alert alert-success" role="alert">
                                    Password Reset Successfull
                                </div>
                            </div>)
                        navigate('/user/list')
                    }

                })
        }
    }


    return (

        <UserWrapper>
            {msg}
            <Grid container spacing={4} className='d-flex justify-content-center'>
                <Grid item xs={12} sm={8} md={8}>
                    <PageTitle>Reset Password</PageTitle>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                        <InputLabel htmlFor="Pwd">Current Password</InputLabel>
                        <Input
                            id="Pwd"
                            type={values.showPwd ? 'text' : 'password'}
                            value={values.Pwd}
                            onChange={handleChange('Pwd')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPwd}
                                        onMouseDown={handleMouseDownPwd}
                                    >
                                        {values.showPwd ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <span className="errMsg">{pwdError}</span>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                        <InputLabel htmlFor="password">New Password</InputLabel>
                        <Input
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <span className="errMsg">{passwordError}</span>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                        <InputLabel htmlFor="reTypePassword">Re-type password</InputLabel>
                        <Input
                            id="reTypePassword"
                            type={values.showReTypePassword ? 'text' : 'password'}
                            value={values.reTypePassword}
                            onChange={handleChange('reTypePassword')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowReTypePassword}
                                        onMouseDown={handleMouseDownReTypePassword}
                                    >
                                        {values.showReTypePassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <span className="errMsg">{retypePasswordError}</span>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/user/list")}>
                        Cancel
                    </Button>&nbsp;&nbsp;
                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={submitUpdate}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </UserWrapper>
    )
}
export default ResetPassword;

import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { TextField, Autocomplete, Grid, Tooltip, IconButton } from '@mui/material';
import config from '../../config.json';
import moment from 'moment';
import { useParams } from "react-router-dom";
import { Button, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import { ClearIcon } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { filter } from 'rxjs';

const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
         background-color: #F59300 !important;
    }
   
    .materialTableStyle {
        .filterAutocomplete{
    
            .closeIcon { 
                position: absolute;
                left: 450px;
                right: 0px;
                top: 20px;
                bottom: 0px;
            }
            .MuiFilledInput-root {
                background-color: #ffffff;
                border-radius: 0;
                height : 35px;
                padding-top : 0px;
            }
            .MuiFilledInput-input {
                padding: none;
                font-size: 12px;
                height : 10px;
            }
            .MuiFilledInput-underline:before {
                border: 0;
            }
            .MuiInputLabel-filled {
                transform: translate(12px,7px) scale(1);
                font-size: 12px;
            }
            .MuiFilledInput-underline:after {
                border: 0;
            }
            .MuiSelect-select:focus {
                background-color: #ffffff;
            }
            .MuiInputLabel-filled.MuiInputLabel-shrink {
                transform: translate(0px,-12px) scale(0.90) !important;
                color: #F59300;
            }}
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionApproveIconPosition{
                        display: block;
                    }
                    .actionRejectIconPosition{
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionApproveIconPosition{
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionRejectIconPosition{
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
        .approveActionIcon {
            font-size: 17px;
            font-weight : 1500px ;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr 1fr 0fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .status {
        display: inline-block;
        padding: 8px 16px;
        border-radius: 20px;
        background-color: orange;
        color: white;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }
`;

const headCells = [
  { id: 'siteName', numeric: false, label: 'Project Site Name' },
  { id: 'status', numeric: false, label: 'Status' },
  { id: 'dispatchDate', numeric: false, label: 'Next Run Date' },
  { id: 'userName', numeric: false, label: 'User/Group Name' }];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  customToastProgress: {
    backgroundColor: '#F59300',
  },
}));

export default function Build() {
  const classes = useStyles();
  const token = config.TOKEN;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  var [userProfile, setUserProfile] = React.useState();
  var [userEmail, setUserEmail] = React.useState("");
  var [userName, setUserName] = React.useState("");
  var [userData, setUserData] = React.useState([]);
  const { id } = useParams();
  var [rows, setRows] = React.useState([]);
  var [filteredRows, setFilteredRows] = React.useState([]);
  var [disable, setDisable] = React.useState(true);
  const currentDate = moment();
  const [startDate, setStartDate] = React.useState(currentDate.clone().startOf('month').format("yyyy-MM-DD"));
  const [endDate, setEndDate] = React.useState(currentDate.clone().endOf('month').format("yyyy-MM-DD"));
  var [siteData, setSiteData] = React.useState([]);
  var [userId, setUserId] = React.useState(null);
  var [siteId, setSiteId] = React.useState(null);
  var [routeId, setRouteId] = React.useState(null);
  var [pfIdArray, setPfIdArray] = React.useState([]);
  var [recordId, setRecordId] = React.useState();
  var [pfId, setPfId] = React.useState([]);
  var [approvePopup, setApprovePopup] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  var [deletePopup, setDeletePopup] = React.useState(false);
  const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });
  const approveMessage = () => toast("Dispatch released Successfully!", { progressClassName: classes.customToastProgress });
  const rejectMessage = () => toast("Form rejected Successfully!", { progressClassName: classes.customToastProgress });
  const notifyApproveWithoutSelected = () => toast("Select atleast one row!", { progressClassName: classes.customToastProgress });
  const deleteMessage = () => toast("Dispatch deleted successfully!", { progressClassName: classes.customToastProgress });
  const errorContactMessage = () => toast("An error occured, contact support", { progressClassName: classes.customToastProgress });

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {userName != "User" && headCell.label == 'Project Site Name' && <Checkbox
                checked={selectAll}
                style={{ color: "#F59300", paddingLeft: "10px" }}
                onChange={(event) => selectAllRows(event)}
              />}
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userEmail = userProfile.userEmail;
    setUserEmail(userEmail);
    userName = userProfile.userName;
    setUserName(userName)

    getUserData()
    getSiteData()

    if (id > 0) {
      getDispatch()
    } else {
      handleSearch()
    }

  }, [])

  const getDispatch = () => {
    const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/dispatch/" + id
    axios.get(MOBILESURETY_SCHEDULE_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          rows = res.data.data
          setRows(rows);
          filteredRows = rows;
          setFilteredRows(filteredRows)
          setDisable(false)
        } else {
          errorMessage()
        }
      })
  }

  const getUserData = () => {
    const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/employee/basic/" + userName
    axios.get(MOBILESURETY_SCHEDULE_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          userData = res.data.data
          setUserData(userData);
        } else {
          errorMessage()
        }
      })
  }

  const getSiteData = () => {
    const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/sites/basic"
    axios.get(MOBILESURETY_SCHEDULE_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          siteData = res.data.data
          setSiteData(siteData);
        } else {
          errorMessage()
        }
      })
  }

  const handleSearch = () => {
    var postData = {
      "startDate": startDate,
      "endDate": endDate,
      "userId": userId,
      "siteId": siteId,
      "routeId": routeId
    }

    const MOBILESURETY_URL = config.API_URL + "/api/dispatch/release"
    const token = config.TOKEN

    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          rows = res.data.data
          setRows(rows);
          filteredRows = rows;
          setFilteredRows(rows);
        } else {
          errorMessage()
        }
      })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    isSelectedCheckboxes(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelectedCheckboxes = (page) => {
    const trimStart = page * rowsPerPage
    const trimEnd = trimStart + rowsPerPage
    const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
    const selectedRows = sortedRows.slice(trimStart, trimEnd)
    let selectAll = false;
    if (selectedRows.every(x => x.status !== 'Released' && x.select === true)) {
      selectAll = true
    } else {
      selectAll = false
    }
    setSelectAll(selectAll)
  }

  const selectAllRows = (event) => {

    setSelectAll(event.target.checked)
    const trimStart = page * rowsPerPage
    const trimEnd = trimStart + rowsPerPage
    const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
    for (let index = trimStart; index < trimEnd; index++) {
      const element = sortedRows[index];
      if (element && element.status !== 'Released') {
        element['select'] = event.target.checked;
      }
    }
    setFilteredRows([...sortedRows]);
  }

  const onChangeCheckedBox = (checked, index) => {
    console.log(filteredRows[index]);
    const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
    let pageIndex = index + (page * rowsPerPage);
    sortedRows[pageIndex]['select'] = checked;
    setFilteredRows([...sortedRows]);
    console.log(checked)
  };

  const openRejectPopup = (filteredRows) => {
    pfId = filteredRows.id;
    setPfId(pfId);
    approvePopup = true
    setApprovePopup(approvePopup);
  }

  const closeRejectPopup = () => {
    approvePopup = false
    setApprovePopup(approvePopup);
  }

  const approveForm = (filteredRows, action) => {
    pfIdArray.push(filteredRows.id)
    setPfIdArray(pfIdArray);
    var postData = {
      "idList": pfIdArray,
      "action": action,
      "userName": userName
    }
    approveReject(postData, action)
  }

  const rejectForm = (action) => {
    pfIdArray.push(pfId)
    setPfIdArray(pfIdArray);
    var postData = {
      "idList": pfIdArray,
      "action": action,
      "userName": userName
    }
    approveReject(postData, action)
  }

  const approveSelected = () => {
    pfIdArray = filteredRows.filter(x => x.select === true && x.status !== "Released").map(f => f.id);
    setPfIdArray(pfIdArray);
    if (pfIdArray.length > 0) {
      var postData = {
        "idList": pfIdArray,
        "action": "Approved",
        "userName": userName
      }

      console.log(postData);

      setSelectAll(false)
      for (let index = 0; index < filteredRows.length; index++) {
        let element = filteredRows[index];
        element['select'] = false
      }
      approveReject(postData, "Approved")
    }
    else {
      notifyApproveWithoutSelected()
    }
  }

  const approveReject = (postData, string) => {
    pfIdArray = [];
    setPfIdArray(pfIdArray);
    console.log(postData);
    {
      const MOBILESURETY_RENTAL_SEARCH_URL = config.API_URL + "/api/dispatch/approval";
      const token = config.TOKEN;

      axios.post(MOBILESURETY_RENTAL_SEARCH_URL, postData, {
        headers: {
          'Authorization': `Basic ${token}`
        }
      })
        .then((res) => {
          if (res.data.successCode === 0) {
            if (string === "Approved") {
              approveMessage()
            }
            else {
              rejectMessage()
              setTimeout(() => {
                approvePopup = false;
                setApprovePopup(approvePopup);
              }, 5000)
            }
            handleSearch()
          } else {
            if (string === "Approved") {
              errorMessage()
            } else {
              errorMessage()
              setTimeout(() => {
                approvePopup = false;
                setApprovePopup(approvePopup)
              }, 2000)
            }
          }
        })
    }
  }

  const deleteDispatches = () => {
    const MOBILESURETY_URL = config.API_URL + "/api/dispatch/delete/" + recordId + "/" + userName
    const token = config.TOKEN
    axios.get(MOBILESURETY_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        {
          if (res.data.successCode === 0) {
            deleteMessage()
            handleSearch()
          } else {
            errorContactMessage()
          }
          setTimeout(() => { setDeletePopup(false) }, 3000)
        }
      })
  }

  const openDeletePopup = (id) => {
    recordId = id;
    setRecordId(recordId)
    deletePopup = true;
    setDeletePopup(deletePopup)
  }


  const approve = (filteredRows) => {
    return (
      <div>
        <div className='actionApproveIconPosition'>
          <Tooltip title='Approve' aria-label='Approve'>
            <IconButton className='actionBtn' aria-label='Approve' onClick={() => approveForm(filteredRows, "Approved")}>
              <CheckIcon className='approveActionIcon' />
            </IconButton>
          </Tooltip>
        </div>
        <div className='actionListDeleteIconPosition'>
          <Tooltip title='Delete' aria-label='Delete'>
            <IconButton className='actionBtn' aria-label='Delete' onClick={() => { openDeletePopup(filteredRows.id) }}>
              <DeleteIcon className='actionIcon' />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <TableWrapper>
      <ToastContainer theme='dark' />
      <div className={`${classes.root} materialTableStyle`}>
        <div className="filterRow">
          <Autocomplete
            options={userData}
            className="filterAutocomplete"
            getOptionLabel={(option) => option.userName}
            onChange={(event, employee) => {
              employee != null ? setUserId(employee.id) : setUserId(null);
            }}
            renderInput={(params) => <TextField {...params} fullWidth label="Select User" variant="filled" />}
          />
          <Autocomplete
            options={siteData}
            className="filterAutocomplete"
            getOptionLabel={(option) => option.projectName}
            onChange={(event, site) => {
              site != null ? setSiteId(site.id) : setSiteId(null);
            }}
            renderInput={(params) => <TextField {...params} fullWidth label="Select Site" variant="filled" />}
          />
          <div></div>
          <Button type="button" variant="contained" color="primary" className="noShadow addBtn" onClick={handleSearch}>
            Search
          </Button>
          <span>
            <Button type="button" variant="contained" color="primary" className="noShadow addBtn" onClick={approveSelected}>
              Release
            </Button>
          </span>
        </div>
        <TableContainer>
          <Dialog open={approvePopup} closeonescape="true" maxWidth="true">
            <TableWrapper>
              <DialogContent className='dialogStyle'>
                <div className={`${classes.root} materialTableStyle`}>
                  Do you want to delete the record?
                </div>
                <DialogActions>
                  <Grid container spacing={4} className="mt-1">
                    <Grid item xs={12} sm={12} md={12} className="text-center">
                      <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={closeRejectPopup}>
                        No
                      </Button>&nbsp;&nbsp;
                      <Button type="button" variant="contained" color="primary" className="noShadow addBtn" onClick={() => {
                        rejectForm("Delete");
                        closeRejectPopup();
                      }}>
                        Yes
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
                <ToastContainer theme='dark' />
              </DialogContent>
            </TableWrapper>
          </Dialog>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            style={{ tableLayout: "fixed" }}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((filteredRows, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={filteredRows.id}
                    >
                      <TableCell>
                        <div className='d-flex align-items-center'>{userName != "User" && filteredRows.status !== 'Released' ? (<Checkbox
                          checked={filteredRows.select === true}
                          style={{ color: "#F59300" }}
                          onChange={(event) => onChangeCheckedBox(event.target.checked, index)}
                        />
                        ) : (
                          <div style={{ marginLeft: "25px" }}></div> 
                        )}
                          <span className='mt-2'>{filteredRows.siteName}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="status">{filteredRows.status}</span>
                      </TableCell>
                      <TableCell>
                        {filteredRows.dispatchDate != null ? filteredRows.dispatchDate = moment(Date.parse(filteredRows.dispatchDate)).format('MM/DD/YYYY hh:mm A') : ""}
                      </TableCell>
                      <TableCell>
                        {filteredRows.userName}
                        {filteredRows.approvalStatus}
                        {approve(filteredRows)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="paginationLabel"
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </TableWrapper>
  )
}


import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Grid, Switch, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;

export default function AddEditSite() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let loggedinUser = localStorage.getItem("UserName");
    var [distributionEmailArray, setDistributionEmailArray] = useState([]);
    var [showDistributionEmail, setShowDistributionEmail] = useState();
    const [distributionEmail, setDistributionEmail] = useState("");
    const [distributionEmailError, setDistributionEmailError] = useState("");
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "client": "",
            "company": "",
            "qbName": "",
            "city": "",
            "address": "",
            "email": "",
            "phone": '',
            "isActive": false,
            "distributionEmail": [],
            "customerId": null,
            "regionId": null
        }
    );
    var [isActive, setIsActive] = useState(false)
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });
    const addCompanySiteMessage = () => toast("Company Site Added Successfully!", { progressClassName: classes.customToastProgress });
    const companySiteUpdateMessage = () => toast("Company Site Updated Successfully!", { progressClassName: classes.customToastProgress });


    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail)

        if (id) {
            const MOBILESURETY_USER_URL = config.API_URL + "/api/sites/" + id
            axios.get(MOBILESURETY_USER_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                        isActive = rows.isActive
                        setIsActive(isActive)
                    } else {
                        errorMessage()
                    }
                })
        }

    }, [])


    return (
        <div>
            <ToastContainer theme='dark' />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Email: '', 
                    Address: rows?.address,
                    City: rows?.city, 
                    ContactName: '', 
                    GateCode: rows?.gateCode, 
                    Mobile: rows?.mobile, 
                    Monthly: rows?.monthly, 
                    ProjectName: rows?.projectName, 
                    PropertyManagement: '', 
                    State: rows?.state,
                    WaterGroup: rows?.waterGroup, 
                    Zipcode: rows?.zipcode
                }}
                validate={values => {

                    const errors = {};
                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}

                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    const postData = {
                        id: id ? id : 0,
                        email: '',
                        address: values.Address,
                        city: values.City,
                        contactName: '',
                        gateCode: values.GateCode,
                        mobile: values.Mobile,
                        monthly: values.Monthly,
                        projectName: values.ProjectName,
                        propertyManagement: '',
                        state: values.State,
                        waterGroup: values.WaterGroup,
                        zipcode: values.Zipcode,
                        isActive: isActive,
                        userName: "test",
                        createdBy: "test",
                        updatedBy: "test"
                    }

                    const MOBILESURETY_URL = config.API_URL + "/api/sites/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                { id ? companySiteUpdateMessage() : addCompanySiteMessage() }
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => { navigate("/sites/list") }, 3000)
                        })
                    setSubmitting(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <ToastContainer theme='dark' />
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <PageTitle>Project</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className="text-right">

                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="Site Name" variant="standard" value={values.ProjectName || ''} onBlur={handleBlur} name="ProjectName" onChange={handleChange} />
                                    <span className="errMsg">{errors.ProjectName && touched.ProjectName && errors.ProjectName}</span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className="pt-3">
                                    <TextField label="Address" variant="standard" value={values.Address || ''} onBlur={handleBlur} name="Address" onChange={handleChange} />
                                    <span className="errMsg">{errors.Address && touched.Address && errors.Address}</span>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="City" variant="standard" value={values.City || ''} onBlur={handleBlur} name="City" onChange={handleChange} />
                                    <span className="errMsg">{errors.City && touched.City && errors.City}</span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="Gate Code" variant="standard" value={values.GateCode || ''} onBlur={handleBlur} name="GateCode" onChange={handleChange} />
                                    <span className="errMsg">{errors.GateCode && touched.GateCode && errors.GateCode}</span>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="Mobile" variant="standard" value={values.Mobile || ''} onBlur={handleBlur} name="Mobile" onChange={handleChange} />
                                    <span className="errMsg">{errors.Mobile && touched.Mobile && errors.Mobile}</span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="Time" variant="standard" value={values.Monthly || ''} onBlur={handleBlur} name="Monthly" onChange={handleChange} />
                                    <span className="errMsg">{errors.Monthly && touched.Monthly && errors.Monthly}</span>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="State" variant="standard" value={values.State || ''} onBlur={handleBlur} name="State" onChange={handleChange} />
                                    <span className="errMsg">{errors.State && touched.State && errors.State}</span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="Zipcode" variant="standard" value={values.Zipcode || ''} onBlur={handleBlur} name="Zipcode" onChange={handleChange} />
                                    <span className="errMsg">{errors.Zipcode && touched.Zipcode && errors.Zipcode}</span>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField label="Water Group" variant="standard" value={values.WaterGroup || ''} onBlur={handleBlur} name="WaterGroup" onChange={handleChange} />
                                    <span className="errMsg">{errors.WaterGroup && touched.WaterGroup && errors.WaterGroup}</span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControlLabel control={<Switch
                                        checked={isActive}
                                        name="status"
                                        color="warning"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={e => setIsActive(e.target.checked)}
                                    />}
                                        label="Active" />
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4} >
                                <Grid item xs={12} sm={12} md={12} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/sites/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )
                }
            </Formik>
        </div>
    );
}

import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import projectLogo from '../../assets/images/headerLogo/formjunctionlogo.jpg';



const HeaderLogo = () => {
  return (
    <Fragment>
      <div className={clsx('app-header-logo', {})}>
      <Box
          className="header-logo-wrapper"
          title="Mobile Surety">
          <Link to="/Dashboard" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn">
              <img
                className="app-header-logo-img"
               //className="h-100 w-100 mb-2 mt-5"
                alt="Mobile Surety"
                src={projectLogo}
              />
            </IconButton>
          </Link>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;

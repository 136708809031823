import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Button, MenuItem, TextField, FormControl, Card, CardContent, Grid, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }

    .cardStyle {
        backgroundColor : blue !important;
    }
`;

export default function AddRoute() {
    const classes = useStyles();
    let tokens = localStorage.getItem("token");
    tokens = JSON.parse(tokens)
    const token = config.TOKEN;
    const { id } = useParams();
    const navigate = useNavigate();
    var [rows, setRows] = React.useState([
        {
            "name": "",
            "qbName": "",
            "email": " ",
            "isActive": false,
            "customerId": null,
            "userId": null
        }
    ]);
    var [userType, setUserType] = React.useState("");
    var [userName, setUserName] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    var [disableEmail, setDisableEmail] = React.useState(false);
    var [userList, setUserList] = React.useState([]);
    const [user, setUser] = React.useState(0);
    const [userFullName, setUserFullName] = React.useState("");
    const [userError, setUserError] = React.useState("");
    var [userArray, setUserArray] = React.useState([]);
    var [showUser, setShowUser] = React.useState();
    const addUserMessage = () => toast("User Group Added successfully!", { progressClassName: classes.customToastProgress });
    const updateUserMessage = () => toast("User Group Updated Successfully!", { progressClassName: classes.customToastProgress });
    const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userType = userProfile.userType;
        setUserType(userType)
        userName = userProfile.userName
        setUserName(userName)

        if (id) {
            disableEmail = true
            setDisableEmail(disableEmail)
            const MOBILESURETY_ROUTE_URL = config.API_URL + "/api/route/" + id
            axios.get(MOBILESURETY_ROUTE_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                        userArray = rows?.userList
                        setUserArray(userArray)
                        showUser = true
                        setShowUser(showUser)
                    } else {
                        errorMessage()
                    }
                })
        }

        getEmployees()

    }, [])

    const getEmployees = () => {
        const MOBILESURETY_USER_URL = config.API_URL + "/api/users/basic"
        axios.get(MOBILESURETY_USER_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    userList = res.data.data
                    setUserList(userList);
                } else {
                    errorMessage()
                }
            })
    }

    const addUser = () => {
        if (!userFullName) {
            setUserError("Enter a user name!")
            setTimeout(() => { setUserError("") }, 4000)
        } else {
            userArray.push({ "userName": userFullName, "fullName": userFullName, "id": user, "userEmail": userFullName })
            setUserArray(userArray)
            setUser('')
            setShowUser(true)
        }
    }

    const deleteUser = (i, e) => {

        setUserArray((userArray) => {
            return userArray.filter((arrElem, index) => {
                return index !== i;
            });
        });
    }


    const displayUser = () => {
        if (showUser)
            return (
                <Grid item xs={12} sm={8} md={8} className="pt-3">
                    <Grid container spacing={4} className='pt-4'>
                        {userArray?.map((e, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={6} key={e.id} >
                                    <Card style={{ backgroundColor: "#F0F0F0" }} >
                                        <CardContent className="d-flex justify-content-between pb-0">
                                            <div>
                                                <p>{e.fullName}</p>
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={() => deleteUser(index, e)} />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                        )}
                    </Grid >
                </Grid>
            )
    }


    return (
        <div>
            <ToastContainer theme='dark' />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Name: rows?.routeName, UserList: rows?.userList ? rows?.userList : null
                }}
                validate={values => {
                    const errors = {};
                    if (!values.Name) {
                        errors.Name = 'Required!';
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        id: id ? id : 0,
                        routeName: values.Name,
                        userList: userArray,
                        userName: userName
                    }
                    const MOBILESURETY_URL = config.API_URL + "/api/route/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                { id ? updateUserMessage() : addUserMessage() }
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => { navigate("/route/list") }, 3000)
                        })
                    setSubmitting(false)
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <ToastContainer theme='dark' />
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-4">
                                    <PageTitle>User Group</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Name" variant="standard" value={values.Name || ''} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                    <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <FormControl style={{ width: "85%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            // fullWidth
                                            style={{ width: "99%" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select User"
                                            helperText=""
                                            variant="standard"
                                            name="User"
                                            value={user}
                                            onBlur={handleBlur}
                                            onChange={e => { setUser(e.target.value); setUserFullName((userList.filter(u => u.id == e.target.value)[0].userName)) }}>
                                            {userList.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.userName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                    <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                        style={{
                                            borderRadius: "50%",
                                            backgroundColor: "rgb(245, 147, 0)",
                                            color: "white",
                                            padding: "5px",
                                            fontSize: "20px",
                                            cursor: "pointer"
                                        }} onClick={() => addUser()} />
                                </Grid>
                                {displayUser()}
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/route/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )}
            </Formik>
        </div>
    );
}

import React, { Fragment } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@mui/material';
import { connect } from 'react-redux';
import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';
import navItems from './navItems';
import navItemsFiltered from './navItemsFiltered';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,

    sidebarShadow
  } = props;

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  let userProfile = localStorage.getItem("userProfile");
  userProfile = JSON.parse(userProfile);
  let userType = userProfile?.userType;

  const sidebarMenuContent = (
    <div>
      {
        userType == "Admin" ? navItems.map(list => (
          <SidebarMenu
            component="div"
            key={list.label}
            pages={list.content}
            title={list.label}
          />
        )) :
          navItemsFiltered.map(list => (
            <SidebarMenu
              component="div"
              key={list.label}
              pages={list.content}
              title={list.label}
            />))
      }

    </div>
  );

  return (
    <Fragment>
      {/* <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
          
        </Drawer>
      </Hidden> */}

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed,
            'app-sidebar-wrapper-hidden' : sidebarToggleMobile
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

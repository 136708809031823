import React, { useEffect, useState } from 'react';
import Moment from 'moment'
import config from '../config.json';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, CardContent } from '@mui/material';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DashboardWrapper = styled.div`
  .dashboardGradientCard {
    height: 110px;
  }

  .font07 {
    font-size: 0.7rem;
  }  

  .dashboardCardHeight {
    height: 100% !important;
  }
`;

const useStyles = makeStyles(() => ({
  customToastProgress: {
    backgroundColor: '#F59300',
  },
}))

export default function Dashboard() {
  const classes = useStyles();
  var [dashboardCount, setDashboardCount] = React.useState({
    routeService: 0,
    managerReport: 0,
    treeTrimming: 0,
    totalCount: 0,
    routeServiceTotal: 0,
    managerReportTotal: 0,
    treeTrimmingTotal: 0
  });
  var [routeServices, setRouteServices] = useState([]);
  var [treeTrimmings, setTreeTrimmings] = useState([]);
  const navigate = useNavigate();
  var [userEmail, setUserEmail] = React.useState("");
  var [userProfile, setUserProfile] = React.useState();
  const currentDate = Moment();
  const [startDate, setStartDate] = React.useState(currentDate.clone().startOf('month'));
  const [endDate, setEndDate] = React.useState(currentDate.clone().endOf('month'));
  const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });
  const token = config.TOKEN
  const options = {
    chart: {
      type: 'pie',
    },
    labels: ['Route Service', 'Manager Report', 'Tree Trimming'],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    }
  };
  const ydtoptions = {
    chart: {
      type: 'pie',
    },
    labels: ['Route Service Total', 'Manager Report Total', 'Tree Trimming Total'],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    }
  };
  const series = [dashboardCount.routeService, dashboardCount.managerReport, dashboardCount.treeTrimming]
  const ydtseries = [dashboardCount.routeServiceTotal, dashboardCount.managerReportTotal, dashboardCount.treeTrimmingTotal]


  useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userEmail = userProfile.userEmail;
    setUserEmail(userEmail)
    const MOBILESURETY_URL = config.API_URL + "/api/dashboard/count/" + userEmail

    axios.get(MOBILESURETY_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          dashboardCount = res.data.data
          setDashboardCount(dashboardCount);
        } else {
          errorMessage()
        }
      })

    var postData = {
      "startDate": startDate,
      "endDate": endDate,
      "formName": "Route Service Report",
      "clientName": "string",
      "submitter": "string",
      "portalPage": "DASHBOARD"
    }
    const MOBILESURETY_FORMSEARCH_URL = config.API_URL + "/api/formsearch"

    axios.post(MOBILESURETY_FORMSEARCH_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          routeServices = res.data.data
          setRouteServices(routeServices)
        } else {
          errorMessage()
        }
      })

    var postData = {
      "startDate": startDate,
      "endDate": endDate,
      "formName": "Tree Trimming Proposal",
      "clientName": "string",
      "submitter": "string",
      "portalPage": "DASHBOARD"
    }
    const MOBILESURETY_TREETRIMMING_URL = config.API_URL + "/api/formsearch"

    axios.post(MOBILESURETY_TREETRIMMING_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          treeTrimmings = res.data.data
          setTreeTrimmings(treeTrimmings)
        } else {
          errorMessage()
        }
      })

  }, []);


  const LastSubmitData = {
    header: ['Client', 'Created Date', 'Submitter']
  }

  const TreeTrimmingProposal = {
    header: ['Client', 'Created Date', 'Submitter']
  }

  return (
    <DashboardWrapper>
      <ToastContainer theme='dark' />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box bg-premium-dark dashboardGradientCard border-0 text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Manager Report
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.managerReport}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-asteroid dashboardGradientCard border-0 text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Route Service
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.routeService}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-midnight-bloom dashboardGradientCard text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Tree Trimming
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.treeTrimming}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-night-sky dashboardGradientCard text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Total
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.totalCount}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Current Month</div>
            </div>
            <CardContent className="p-3">
              <Chart options={options} type="pie" series={series} width="320" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">YTD Details</div>
            </div>
            <CardContent className="p-3">
              <Chart options={ydtoptions} type="pie" series={ydtseries} width="350" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>


      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="card-box noShadow mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Route Service Report</div>
            </div>
            <CardContent className="p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-hover text-nowrap mb-0">
                  <thead>
                    <tr>
                      {LastSubmitData.header.map((headerItem, index) => (
                        <th className="text-left" key={index}>{headerItem}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {routeServices.slice(0, 5).map((routeService, index) =>

                    (
                      <tr key={routeService.id}>
                        <td className="text-left" style={{ width: '40%' }}>
                          <div className="text-black font07">{routeService.client}</div>
                        </td>
                        <td className="text-left" style={{ width: '30%' }}>
                          <div className="text-black font07">{routeService.createdDate = Moment(Date.parse(routeService.createdDate)).format('MM/DD/YYYY')}</div>
                        </td>
                        <td className="text-left" style={{ width: '30%' }}>
                          <div className="text-black font07">{routeService.submitter}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="card-box noShadow mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Tree Trimming Proposal</div>
            </div>
            <CardContent className="p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-hover text-nowrap mb-0">
                  <thead>
                    <tr>
                      {TreeTrimmingProposal.header.map((headerItem, index) => (
                        <th className="text-left" key={index}>{headerItem}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {treeTrimmings.slice(0, 5).map((treeTrimming, index) =>

                    (
                      <tr key={treeTrimming.id}>
                        <td className="text-left" style={{ width: '40%' }}>
                          <div className="text-black font07" >{treeTrimming.client}</div>
                        </td>
                        <td className="text-left" style={{ width: '30%' }}> 
                          <div className="text-black font07" >{treeTrimming.createdDate = Moment(Date.parse(treeTrimming.createdDate)).format('MM/DD/YYYY')}</div>
                        </td>
                        <td className="text-left" style={{ width: '30%' }}>
                          <div className="text-black font07" >{treeTrimming.submitter}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </DashboardWrapper>
  );
}



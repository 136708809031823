import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import CodeIcon from '@mui/icons-material/Code';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import GradeTwoTone from '@mui/icons-material/GradeTwoTone';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import Domain from '@mui/icons-material/Domain';
import KayakingIcon from '@mui/icons-material/Kayaking';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidIcon from '@mui/icons-material/Paid';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListIcon: ListIcon,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  PeopleOutlined: PeopleOutlined,
  Domain: Domain,
  AccessTimeIcon: AccessTimeIcon,
  KayakingIcon: KayakingIcon,
  GroupsIcon: GroupsIcon,
  PaymentsIcon: PaymentsIcon,
  PaidIcon: PaidIcon,
  DateRangeIcon: DateRangeIcon,
  ApartmentIcon: ApartmentIcon,
  LocationOnIcon: LocationOnIcon,
  HouseSidingIcon: HouseSidingIcon,
  TaskOutlined: TaskOutlinedIcon,
  ScheduleIcon: ScheduleIcon ,
  LocalShippingIcon: LocalShippingIcon,
  AssessmentIcon: AssessmentIcon,
  AssignmentIcon: AssignmentIcon,
  NewReleasesOutlinedIcon: NewReleasesOutlinedIcon
};


export default [
  {
    label: ' ',
    content: JSON.parse(
      `[
          {
            "label": "Dashboard",
            "icon": "DashboardTwoToneIcon",
            "to": "/dashboard"
          },
         
          {
            "label": "Users",
            "icon": "PeopleOutlined",
            "to": "/user/list"
          },
          {
            "label": "Groups",
            "icon": "GroupsIcon",
              "to": "/route/list"
          },
          {
            "label": "Project Sites",
            "icon": "LocationOnIcon",
              "to": "/sites/list"
          },
          {
            "label": "Manager Service Report",
            "icon": "AssessmentIcon",
              "to": "/manager/report"
          },
          {
            "label": "Route Service Report",
            "icon": "ReceiptIcon",
            "to": "/route/report"
          },
          {
            "label": "Tree Trimming Proposal",
            "icon": "AssignmentIcon",
            "to": "/trimming/proposal"
          },
          {
            "label": "Form Scheduler",
            "icon": "ScheduleIcon",
            "to": "/scheduler"
          },
          {
            "label": "Form Dispatches",
            "icon": "LocalShippingIcon",
            "to": "/dispatches/list/0"
          },
          {
            "label": "Form Release",
            "icon": "NewReleasesOutlinedIcon",
            "to": "/release/list"
          },
          {
            "label": "Task List",
            "icon": "TaskOutlined",
            "to": "/sites/task"
          }
        ]`,

      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }


]
/* 

  {
    "label": "Users",
    "icon": "PeopleIcon",
    "to": "/user/list"
  },
   {
            "label": "Company Sites",
            "icon": "ApartmentIcon",
             "to": "/client/list"
          },
          {
            "label": "Client Users",
            "icon": "PeopleOutlined",
            "to": "/client/users/list"
          },
            {
            "label": "Payroll Sync",
            "icon": "PaidIcon",
            "to": "/payrollsync/list"
          },
          {
            "label": "QuickBooks Sync Logs",
            "icon": "DateRangeIcon",
            "to": "/batchdates/list"
          },
          {
            "label": "Payroll Mapping",
            "icon": "PaidIcon",
            "to": "/payroll/mapping"
          }
*/

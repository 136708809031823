import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Grid, Switch, FormControlLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';


const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;

const useStyles = makeStyles((theme) => ({
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}))

export default function EditDispatch() {
    const classes = useStyles();
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "name": "",
            "contactName": "",
            "contactPhone": "",
            "clientId": ""
        }
    );
    var [isActive, setIsActive] = useState(false);
    const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });
    const updateScheduleMessage = () => toast("Schedule Updated Successfully!", { progressClassName: classes.customToastProgress });
    var [userData, setUserData] = React.useState([]);
    var [siteData, setSiteData] = React.useState([]);
    const [user, setUser] = React.useState('');
    var [userName, setUserName] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userName = userProfile.userName;
        setUserName(userName)
        if (id) {
            const MOBILESURETY_URL = config.API_URL + "/api/dispatch/single/" + id
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                        rows.dispatchDate = moment(Date.parse(rows?.dispatchDate)).format('YYYY-MM-DD')
                        setRows(rows)
                    } else {
                        errorMessage()
                    }
                })
        }
        getUserData()
        getSiteData()
    }, [])

    const getUserData = () => {
        const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/employee/basic/" + userName
        axios.get(MOBILESURETY_SCHEDULE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    userData = res.data.data
                    setUserData(userData);
                } else {
                    errorMessage()
                }
            })
    }

    const getSiteData = () => {
        const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/sites/basic"
        axios.get(MOBILESURETY_SCHEDULE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    siteData = res.data.data
                    setSiteData(siteData);
                } else {
                    errorMessage()
                }
            })
    }

    return (
        <div>
            <ToastContainer theme='dark' />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    DispatchDate: rows?.dispatchDate, SiteId: rows?.siteId, UserId: rows?.userId
                }}
                validate={values => {

                    const errors = {};

                    // if (!values.PartNumber) {
                    //     errors.Name = 'Required!';
                    // }
                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        id: id ? id : 0,
                        dispatchDate: values.DispatchDate,
                        siteId: values.SiteId,
                        userId: values.UserId
                    }

                    const MOBILESURETY_URL = config.API_URL + "/api/dispatch/save"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                updateScheduleMessage()
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => {
                                navigate("/dispatches/list/0")
                            }, 3000);
                        })
                    setSubmitting(false)
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <ToastContainer theme='dark' />
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <PageTitle>Schedule</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">

                                </Grid>

                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField
                                        style={{ width: "750px" }}
                                        id="Date"
                                        label="Dispatch Date"
                                        type="date"
                                        color="primary"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                        name="DispatchDate"
                                        value={values.DispatchDate}
                                        onChange={handleChange}
                                    />
                                    {/* <span className="errMsg">{errors.PartNumber && touched.PartNumber && errors.PartNumber}</span> */}
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField
                                        style={{ width: "750px" }}
                                        className="filterDropdown"
                                        id="status"
                                        select
                                        label="Select Site"
                                        helperText=""
                                        variant="standard"
                                        name="SiteId"
                                        value={values.SiteId || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}>
                                        {siteData.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.projectName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {/* <span className="errMsg">{errors.Category && touched.Category && errors.Category}</span> */}
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField
                                        style={{ width: "750px" }}
                                        className="filterDropdown"
                                        id="status"
                                        select
                                        label="Select User"
                                        helperText=""
                                        variant="standard"
                                        name="UserId"
                                        value={values.UserId || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}>
                                        {userData.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.userName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {/* <span className="errMsg">{errors.Description && touched.Description && errors.Description}</span> */}
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/dispatches/list/0")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )
                }
            </Formik >
        </div>
    );
}

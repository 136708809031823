import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Button, MenuItem, TextField, FormControl, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }

    .cardStyle {
        backgroundColor : blue !important;
    }
`;

export default function AddUser() {
    const classes = useStyles();
    let tokens = localStorage.getItem("token");
    tokens = JSON.parse(tokens)
    const token = config.TOKEN;
    const { id } = useParams();
    const navigate = useNavigate();
    var [rows, setRows] = React.useState([
        {
            "name": "",
            "qbName": "",
            "email": " ",
            "isActive": false,
            "customerId": null,
            "regionId": null
        }
    ]);
    var [userType, setUserType] = React.useState("");
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    var [disableEmail, setDisableEmail] = React.useState(false);
    const addUserMessage = () => toast("User Added successfully!", { progressClassName: classes.customToastProgress });
    const updateUserMessage = () => toast("Employee Info Updated Successfully!", { progressClassName: classes.customToastProgress });
    const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userType = userProfile.userType;
        setUserType(userType)
        userEmail = userProfile.userEmail
        setUserEmail(userEmail)

        if (id) {
            disableEmail = true
            setDisableEmail(disableEmail)
            const MOBILESURETY_USER_URL = config.API_URL + "/api/user/" + id
            axios.get(MOBILESURETY_USER_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                    } else {
                        errorMessage()
                    }
                })
        }

    }, [])

    const UserTypeList = ["Admin", "Manager", "User"]

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Name: rows?.fullName, Email: rows?.userEmail, IsActive: rows?.isActive,
                    UserType: rows?.userType ? rows?.userType : null,
                    Password: (rows?.userEmail ? "     " : ""),
                    PfId: rows?.pfId
                }}
                validate={values => {
                    const errors = {};
                    if (!values.Name) {
                        errors.Name = 'Required!';
                    }

                    if (!values.Email) {
                        errors.Email = 'Required!';
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        id: id ? id : 0,
                        userName: values.Name,
                        fullName: values.Name,
                        userEmail: values.Email,
                        isActive: values.IsActive,
                        userType: values.UserType,
                        pwd: values.Password,
                        pfId: values.PfId,
                        userKey: "userkey",
                        token: "token"
                    }
                    // same api for add & edit
                    const MOBILESURETY_URL = config.API_URL + "/api/user/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                { id ? updateUserMessage() : addUserMessage() }
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => { navigate("/user/list") }, 3000)
                        })
                    setSubmitting(false)
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <ToastContainer theme='dark' />
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-4">
                                    <PageTitle>Employee</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Name" variant="standard" value={values.Name || ''} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                    <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} disabled={disableEmail} name="Email" onChange={handleChange} />
                                    <span className="errMsg">{errors.Email && touched.Email && errors.Email}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="TrueContext ID" variant="standard" value={values.PfId || ''} onBlur={handleBlur} name="PfId" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            // fullWidth
                                            style={{ width: "99%" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="User Type"
                                            helperText=""
                                            variant="standard"
                                            name="UserType"
                                            value={values.UserType || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {UserTypeList.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField type="password" label="Password" variant="standard"
                                        value={values.Password || ''} onBlur={handleBlur} name="Password" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/user/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )}
            </Formik>
        </div>
    );
}

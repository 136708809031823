import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Grid, Switch, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;

export default function AddEditPart() {
    const classes = useStyles();
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "name": "",
            "contactName": "",
            "contactPhone": "",
            "clientId": ""
        }
    );
    var [isActive, setIsActive] = useState(false);
    const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });
    const partAddMessage = () => toast("Part Added Successfully!", { progressClassName: classes.customToastProgress });
    const updatePartMessage = () => toast("Part Updated Successfully!", { progressClassName: classes.customToastProgress });


    useEffect(() => {

        if (id) {
            const MOBILESURETY_URL = config.API_URL + "/api/parts/" + id
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                    } else {
                        errorMessage()
                    }
                })
        }

    }, [])

    return (
        <div>
            <ToastContainer theme='dark' />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    PartNumber: rows?.partNumber, Category: rows?.category, Description: rows?.description, Sbmanager: rows?.sbmanager, Email: rows?.email, LicensePlate: rows?.licensePlate,
                    TrailerPlate: rows?.trailerPlate, Hedger: rows?.hedger, LineTrimmer: rows?.lineTrimmer, Blower: rows?.blower, Mower: rows?.mower
                }}
                validate={values => {

                    const errors = {};

                    if (!values.PartNumber) {
                        errors.Name = 'Required!';
                    }
                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        id: id ? id : 0,
                        partNumber: values.PartNumber,
                        category: values.Category,
                        description: values.Description,
                        sbmanager: values.Sbmanager,
                        email: values.Email,
                        licensePlate: values.LicensePlate,
                        trailerPlate: values.TrailerPlate,
                        hedger: values.Hedger,
                        lineTrimmer: values.LineTrimmer,
                        blower: values.Blower,
                        mower: values.Mower,
                        isActive: isActive,
                        userName: "test",
                        createdBy: "test",
                        updatedBy: "test"
                    }

                    const MOBILESURETY_URL = config.API_URL + "/api/parts/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                { id ? updatePartMessage() : partAddMessage() }
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => {
                                navigate("/part/list")
                            }, 3000);
                        })
                    setSubmitting(false)
                }}

            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <ToastContainer theme='dark' />
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <PageTitle>Part</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">

                                </Grid>

                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Part Number" variant="standard" value={values.PartNumber || ''} onBlur={handleBlur} name="PartNumber" onChange={handleChange} />
                                    <span className="errMsg">{errors.PartNumber && touched.PartNumber && errors.PartNumber}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Category" variant="standard" value={values.Category || ''} onBlur={handleBlur} name="Category" onChange={handleChange} />
                                    <span className="errMsg">{errors.Category && touched.Category && errors.Category}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Description" variant="standard" value={values.Description || ''} onBlur={handleBlur} name="Description" onChange={handleChange} />
                                    <span className="errMsg">{errors.Description && touched.Description && errors.Description}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Sb Manager" variant="standard" value={values.Sbmanager || ''} onBlur={handleBlur} name="Sbmanager" onChange={handleChange} />
                                    <span className="errMsg">{errors.Sbmanager && touched.Sbmanager && errors.Sbmanager}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} name="Email" onChange={handleChange} />
                                    <span className="errMsg">{errors.Email && touched.Email && errors.Email}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="License Plate" variant="standard" value={values.LicensePlate || ''} onBlur={handleBlur} name="LicensePlate" onChange={handleChange} />
                                    <span className="errMsg">{errors.LicensePlate && touched.LicensePlate && errors.LicensePlate}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Trailer Plate" variant="standard" value={values.TrailerPlate || ''} onBlur={handleBlur} name="TrailerPlate" onChange={handleChange} />
                                    <span className="errMsg">{errors.TrailerPlate && touched.TrailerPlate && errors.TrailerPlate}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Hedger" variant="standard" value={values.Hedger || ''} onBlur={handleBlur} name="Hedger" onChange={handleChange} />
                                    <span className="errMsg">{errors.Hedger && touched.Hedger && errors.Hedger}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Line Trimmer" variant="standard" value={values.LineTrimmer || ''} onBlur={handleBlur} name="LineTrimmer" onChange={handleChange} />
                                    <span className="errMsg">{errors.LineTrimmer && touched.LineTrimmer && errors.LineTrimmer}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Blower" variant="standard" value={values.Blower || ''} onBlur={handleBlur} name="Blower" onChange={handleChange} />
                                    <span className="errMsg">{errors.Blower && touched.Blower && errors.Blower}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Mower" variant="standard" value={values.Mower || ''} onBlur={handleBlur} name="Mower" onChange={handleChange} />
                                    <span className="errMsg">{errors.Mower && touched.Mower && errors.Mower}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <FormControlLabel control={<Switch
                                        checked={isActive}
                                        name="status"
                                        color="warning"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={e => setIsActive(e.target.checked)}
                                    />}
                                        label="Is Active" />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/part/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )
                }
            </Formik >
        </div>
    );
}

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, TextField, Grid, MenuItem, Checkbox, FormControlLabel, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import config from '../../config.json';
import { useParams } from "react-router-dom";
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { useLocation } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone';
import { ClipLoader } from 'react-spinners';
import Papa from 'papaparse';

const useStyles = makeStyles(() => ({
    App: {
        textAlign: 'center',
    },
    geeks: {
        color: 'green',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '35rem',
        margin: '2% auto',
        boxShadow: '0px 5px 10px gray',
        borderRadius: '15px',
        padding: '3%',
    },
    item: {
        width: '200px',
        margin: '0 auto',
        padding: '2px',
        borderRadius: '10px',
        textAlign: 'left',
    },
    label: {
        width: '150px',
        borderRadius: '5px',
        backgroundColor: '#F59300',
        color: 'white',
        fontSize: 'larger',
        margin: 'auto',
        padding: '3px',
        cursor: 'pointer'
    },
    csvInput: {
        display: 'none',
    },
    dropContainer: {
        border: '2px dashed #F59300',
        borderRadius: '15px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCell: {
        color: '#F59300',
        '&:hover': {
            backgroundColor: '#F59300',
            color: 'white',
        },
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const headCells = [
    { id: 'refNumber', numeric: false, label: 'Number' },
    { id: 'errorMsg', numeric: false, label: 'Error Message' },
]

const allowedExtensions = ["csv"];

export default function BasicTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const weekList = [1, 2, 3, 4]
    const dayList = Array.from({ length: 31 }, (_, index) => index + 1);
    const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const token = config.TOKEN;
    const { id } = useParams();
    var [rows, setRows] = React.useState([]);
    var [userName, setUserName] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    const navigate = useNavigate();
    var [formData, setFormData] = React.useState([]);
    const entityType = ["USER", "ROUTE"];
    var [entity, setEntity] = React.useState("");
    var [entityName, setEntityName] = React.useState("");
    var [userData, setUserData] = React.useState([]);
    var [routeData, setRouteData] = React.useState([]);
    var [siteData, setSiteData] = React.useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('interval');
    var [hour, setHour] = React.useState(dayjs('2024-01-01T15:30:00'));
    var [startDate, setStartDate] = React.useState("");
    var [weekDays, setWeekDays] = React.useState([]);
    const errorMessage = () => toast("An error occured,try again later", { progressClassName: classes.customToastProgress });
    const updateScheduleMessage = () => toast("Schedule Updated Successfully!", { progressClassName: classes.customToastProgress });
    const addScheduleMessage = () => toast("Schedule Added successfully!", { progressClassName: classes.customToastProgress });
    const [error, setError] = React.useState("");
    const [file, setFile] = React.useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const [errorData, setErrorData] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [errors, setErrors] = useState({});

    React.useEffect(() => {
        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userName = userProfile.userName;
        setUserName(userName)

        if (paramValue == "daily") {
            setValue(1)
        } else if (paramValue == "weekly") {
            setValue(2)
        } else if (paramValue == "monthly") {
            setValue(3)
        }

        if (id) {
            const MOBILESURETY_URL = config.API_URL + "/api/schedule/" + id
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data

                        setRows(rows);
                        startDate = Moment(Date.parse(rows?.startDate)).format('YYYY-MM-DD')
                        setStartDate(startDate)
                        entity = rows?.entityId
                        setEntity(entity)
                        weekDays = rows?.weekday
                        setWeekDays(weekDays);
                        hour = dayjs('2022-04-17T' + rows?.scheduleTime);
                        setHour(hour)
                    } else {
                        errorMessage()
                    }
                })
        }
        getFormData()
        getUserData()
        getRouteData()
        getSiteData()
    }, [])

    const getFormData = () => {
        const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/formschema"
        axios.get(MOBILESURETY_SCHEDULE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    formData = res.data.data
                    setFormData(formData);
                } else {
                    errorMessage()
                }
            })
    }

    const getUserData = () => {
        const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/employee/basic/" + userName
        axios.get(MOBILESURETY_SCHEDULE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    userData = res.data.data
                    setUserData(userData);
                } else {
                    errorMessage()
                }
            })
    }

    const getSiteData = () => {
        const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/sites/basic"
        axios.get(MOBILESURETY_SCHEDULE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    siteData = res.data.data
                    setSiteData(siteData);
                } else {
                    errorMessage()
                }
            })
    }

    const getRouteData = () => {
        const MOBILESURETY_SCHEDULE_URL = config.API_URL + "/api/routes/basic"
        axios.get(MOBILESURETY_SCHEDULE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    routeData = res.data.data
                    setRouteData(routeData);
                } else {
                    errorMessage()
                }
            })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCheckboxChange = (value) => {
        if (weekDays.includes(value)) {
            weekDays = weekDays.filter(item => item !== value)
            setWeekDays(weekDays);
        } else {
            weekDays = [...weekDays, value]
            setWeekDays(weekDays);
        }
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }


    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ color: '#F59300', '&:hover': { color: '#F59300' } }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >

                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onDrop = (acceptedFiles) => {
        const input = acceptedFiles[0];
        handleFileChange(input);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv',
        maxFiles: 1,
    });

    const handleFileChange = (input) => {
        const fileExtension = input.name.split('.').pop();

        if (!allowedExtensions.includes(fileExtension)) {
            setError('Please input a csv file');
            return;
        }

        setFile(input);
        setSelectedFiles([input]);
    };

    const handleParse = () => {

        if (!file) return alert("Enter a valid file");
        setLoading(true);

        const reader = new FileReader();

        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, {
                header: true,
            });
            const parsedData = csv?.data;
            const rows = Object.keys(parsedData[0]);
            const columns = Object.values(parsedData[0]);
            const res = rows.reduce((acc, e, i) => {

                return [...acc, [[e], columns[i]]];
            }, []);
            setData(res);
            const postData = {
                userName: userName,
                fileName: "Rent-import-dummy.csv",
                fileData: JSON.stringify(parsedData)
            };
            const MOBILESURETY_URL = config.API_URL + "/api/rent/upload";
            axios.post(MOBILESURETY_URL, postData, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    setLoading(false);
                    if (res.data.successCode === 0) {
                        const { success, failed, records } = res.data.data;
                        setRows(records);
                        setFilteredRows(records);
                        toast.success(`Rent File upload completed with ${success} Success and ${failed} Failed records.`, { progressClassName: classes.customToastProgress });
                    } else {
                        setErrorData(res.data.data.records || []);
                        errorMessage()
                    }
                })
        };
        reader.readAsText(file);
    };

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ Padding: "10px" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ color: "#F59300" }}>
                        <Tab label="One Time" {...a11yProps(0)} style={{ color: "#F59300" }} />
                        <Tab label="Daily" {...a11yProps(1)} style={{ color: "#F59300" }} />
                        <Tab label="Weekly" {...a11yProps(2)} style={{ color: "#F59300" }} />
                        <Tab label="Monthly" {...a11yProps(3)} style={{ color: "#F59300" }} />
                        {/*<Tab label="Upload" {...a11yProps(4)} style={{ color: "#F59300" }} />*/}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            IntervalCount: rows?.intervalCount, Hour: rows?.hour, StartDate: startDate, Hour: rows?.hour,
                            FormId: rows?.formId || formData.find(option => option.formSchemaName === "Route Service Report")?.id || '',
                            SiteId: rows?.siteId,
                            EntityId: rows?.entityId,
                            EntityType: rows?.entityType || 'USER',
                            NextRunDate: rows?.nextRunDate,
                            Id: rows?.id
                        }}
                        validate={values => {

                            const errors = {};
                            if (!values.StartDate) {
                                errors.StartDate = 'Required!'
                            }
                            if (!values.SiteId) {
                                errors.SiteId = 'Required!'
                            }
                            if (!values.FormId) {
                                errors.FormId = 'Required!'
                            }
                            if (!values.EntityType) {
                                errors.EntityType = 'Required!'
                            }
                            if (!entity) {
                                errors.EntityId = 'Required!'
                            }

                            return errors;
                        }}

                        const handleMouseDownPwd={(event) => {
                            event.preventDefault();
                        }}

                        onSubmit={(values, { setSubmitting }) => {
                            const postData = {
                                interval: "One Time",
                                scheduleTime: hour.$H.toString().padStart(2, '0') + ":" + hour.$m.toString().padStart(2, '0') + ":00",
                                // hour: hour,
                                startDate: values.StartDate,
                                formId: values.FormId,
                                siteId: values.SiteId,
                                entityId: entity,
                                entityType: values.EntityType,
                                entityName: entityName,
                                userName: userName,
                                id: values.Id
                            }
                            console.log(postData)

                            const MOBILESURETY_URL = config.API_URL + "/api/schedule/add"
                            axios.post(MOBILESURETY_URL, postData, {
                                headers: {
                                    'Authorization': `Basic ${token}`
                                }
                            })
                                .then((res) => {

                                    if (res.data.successCode === 0) {
                                        { id ? updateScheduleMessage() : addScheduleMessage() }
                                    } else {
                                        errorMessage()
                                    }
                                    setTimeout(() => { navigate("/scheduler") }, 3000)
                                })
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,

                        }) => (
                            <form onSubmit={handleSubmit}>
                                <ToastContainer theme='dark' />
                                <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} className="pt-5">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker
                                                    label="Scheduled Time"
                                                    value={hour}
                                                    minutesStep="15"
                                                    onChange={(e) => { hour = e; setHour(hour); }}
                                                    slotProps={{ textField: { style: { width: "750px" } } }}
                                                    defaultValue={dayjs('2024-01-01T15:30:00')}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            id="Date"
                                            label="Start Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="StartDate"
                                            value={values.StartDate}
                                            onChange={handleChange}
                                        />
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.StartDate && touched.StartDate && errors.StartDate}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Site"
                                            helperText=""
                                            variant="standard"
                                            name="SiteId"
                                            value={values.SiteId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {siteData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.projectName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.SiteId && touched.SiteId && errors.SiteId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Form"
                                            helperText=""
                                            variant="standard"
                                            name="FormId"
                                            value={values.FormId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {formData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.formSchemaName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.FormId && touched.FormId && errors.FormId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="User/Route"
                                            helperText=""
                                            variant="standard"
                                            name="EntityType"
                                            value={values.EntityType || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {entityType.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.EntityType && touched.EntityType && errors.EntityType}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select User/Group Name"
                                            helperText=""
                                            variant="standard"
                                            // name="EntityName"
                                            value={entity || ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => { setEntity(e.target.value); values.EntityType == "USER" ? setEntityName((userData.filter(u => u.id == e.target.value)[0].userName)) : setEntityName((routeData.filter(u => u.id == e.target.value)[0].routeName)) }}>
                                            {values.EntityType == "USER" ? userData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.userName}
                                                </MenuItem>
                                            )) : routeData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.routeName}
                                                </MenuItem>))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{(errors.EntityId && touched.EntityId && !entity) && errors.EntityId}</div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "100%" }}
                                            id="Date"
                                            label="Next Run Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="NextRunDate"
                                            value={values.NextRunDate}
                                            onChange={handleChange}
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField style={{ width: "100%" }} label="Reminder(Optional)" variant="standard" name="Reminder"
                                            value={values.Reminder || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                    </Grid> */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className="text-right mt-5" style={{ paddingRight: "70px" }}>
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/scheduler")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" >
                                        Schedule
                                    </Button>
                                </Grid>
                                <br></br>
                            </form>
                        )
                        }
                    </Formik >
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            IntervalCount: rows?.intervalCount, StartDate: startDate,
                            Hour: rows?.hour,
                            FormId: rows?.formId || formData.find(option => option.formSchemaName === "Route Service Report")?.id || '',
                            SiteId: rows?.siteId,
                            EntityId: rows?.entityId, EntityType: rows?.entityType || 'USER',
                            NextRunDate: rows?.nextRunDate,
                            Id: rows?.id
                        }}
                        validate={values => {

                            const errors = {};
                            if (!values.IntervalCount) {
                                errors.IntervalCount = 'Required!';
                            }
                            if (!values.StartDate) {
                                errors.StartDate = 'Required!'
                            }
                            if (!values.SiteId) {
                                errors.SiteId = 'Required!'
                            }
                            if (!values.FormId) {
                                errors.FormId = 'Required!'
                            }
                            if (!values.EntityType) {
                                errors.EntityType = 'Required!'
                            }
                            if (!entity) {
                                errors.EntityId = 'Required!'
                            }

                            return errors;
                        }}

                        const handleMouseDownPwd={(event) => {
                            event.preventDefault();
                        }}

                        onSubmit={(values, { setSubmitting }) => {
                            const postData = {
                                interval: "Daily",
                                intervalCount: values.IntervalCount,
                                startDate: values.StartDate,
                                scheduleTime: hour.$H.toString().padStart(2, '0') + ":" + hour.$m.toString().padStart(2, '0') + ":00",
                                // hour: hour,
                                minute: 0,
                                formId: values.FormId,
                                siteId: values.SiteId,
                                entityId: entity,
                                entityType: values.EntityType,
                                entityName: entityName,
                                userName: userName,
                                id: values.Id
                                // nextRunDate: values.NextRunDate,
                                // reminder: values.Reminder
                            }

                            const MOBILESURETY_URL = config.API_URL + "/api/schedule/add"
                            axios.post(MOBILESURETY_URL, postData, {
                                headers: {
                                    'Authorization': `Basic ${token}`
                                }
                            })
                                .then((res) => {

                                    if (res.data.successCode === 0) {
                                        { id ? updateScheduleMessage() : addScheduleMessage() }
                                    } else {
                                        errorMessage()
                                    }
                                    setTimeout(() => { navigate("/scheduler") }, 3000)
                                })
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,

                        }) => (
                            <form onSubmit={handleSubmit}>
                                <ToastContainer theme='dark' />
                                <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} className="pt-5">
                                        <TextField
                                            // fullWidth
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Every day(s)"
                                            helperText=""
                                            variant="standard"
                                            name="IntervalCount"
                                            value={values.IntervalCount || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {dayList.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.IntervalCount && touched.IntervalCount && errors.IntervalCount}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker
                                                    label="Scheduled Time"
                                                    value={hour}
                                                    minutesStep="15"
                                                    //  onChange={(e) => { hour = e.$H.toString().padStart(2, '0') + ":" + e.$m.toString().padStart(2, '0') + ":00"; setHour(hour); }}
                                                    onChange={(e) => { hour = e; setHour(hour) }}
                                                    slotProps={{ textField: { style: { width: "750px" } } }}
                                                    defaultValue={dayjs('2024-01-01T15:30:00')}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {/* <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="At Time"
                                            helperText=""
                                            variant="standard"
                                            name="Hour"
                                            value={hour || ''}
                                            onBlur={handleBlur}
                                            onChange={e => { hour = e.target.value; setHour(hour); console.log(hour) }}
                                        >
                                            {options.map(option => (
                                                <MenuItem key={option.key} value={option.key}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        {/* <span className="errMsg">{errors.Region && touched.Region && errors.Region}</span>  */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            id="Date"
                                            label="Start Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="StartDate"
                                            value={values.StartDate}
                                            onChange={handleChange}
                                        />
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.StartDate && touched.StartDate && errors.StartDate}</div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Site"
                                            helperText=""
                                            variant="standard"
                                            name="SiteId"
                                            value={values.SiteId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {siteData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.projectName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.SiteId && touched.SiteId && errors.SiteId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Form"
                                            helperText=""
                                            variant="standard"
                                            name="FormId"
                                            value={values.FormId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {formData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.formSchemaName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg">{errors.FormId && touched.FormId && errors.FormId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="User/Route"
                                            helperText=""
                                            variant="standard"
                                            name="EntityType"
                                            value={values.EntityType || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {entityType.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.EntityType && touched.EntityType && errors.EntityType}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select User/Group Name"
                                            helperText=""
                                            variant="standard"
                                            // name="EntityName"
                                            value={entity || ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => { setEntity(e.target.value); values.EntityType == "USER" ? setEntityName((userData.filter(u => u.id == e.target.value)[0].userName)) : setEntityName((routeData.filter(u => u.id == e.target.value)[0].routeName)) }}>
                                            {values.EntityType == "USER" ? userData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.userName}
                                                </MenuItem>
                                            )) : routeData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.routeName}
                                                </MenuItem>))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{(errors.EntityId && touched.EntityId && !entity) && errors.EntityId}</div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "100%" }}
                                            id="Date"
                                            label="Next Run Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="NextRunDate"
                                            value={values.NextRunDate}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField style={{ width: "100%" }} label="Reminder(Optional)" variant="standard" name="Reminder"
                                            value={values.Reminder || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                    </Grid> */}

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className="text-right mt-4 mx-3" style={{ paddingRight: "70px" }}>
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/scheduler")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" >
                                        Schedule
                                    </Button>
                                </Grid>
                                <br></br>
                            </form>
                        )
                        }
                    </Formik >
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            IntervalCount: rows?.intervalCount, StartDate: startDate, Hour: rows?.hour,
                            FormId: rows?.formId || formData.find(option => option.formSchemaName === 'Route Service Report')?.id || '',
                            SiteId: rows?.siteId,
                            EntityId: rows?.entityId,
                            Id: rows?.id,
                            EntityType: rows?.entityType || 'USER',
                            NextRunDate: rows?.nextRunDate, Weekday: rows?.weekday
                        }}
                        validate={values => {

                            const errors = {};
                            if (!values.IntervalCount) {
                                errors.IntervalCount = 'Required!';
                            }
                            if (!values.StartDate) {
                                errors.StartDate = 'Required!'
                            }
                            if (!values.SiteId) {
                                errors.SiteId = 'Required!'
                            }
                            if (!values.FormId) {
                                errors.FormId = 'Required!'
                            }
                            if (!values.EntityType) {
                                errors.EntityType = 'Required!'
                            }
                            if (!entity) {
                                errors.EntityId = 'Required!'
                            }

                            return errors;
                        }}

                        const handleMouseDownPwd={(event) => {
                            event.preventDefault();
                        }}

                        onSubmit={(values, { setSubmitting }) => {
                            const postData = {
                                interval: "Weekly",
                                intervalCount: values.IntervalCount,
                                startDate: values.StartDate,
                                scheduleTime: hour.$H.toString().padStart(2, '0') + ":" + hour.$m.toString().padStart(2, '0') + ":00",
                                // hour: hour,
                                minute: 0,
                                formId: values.FormId,
                                siteId: values.SiteId,
                                entityId: entity,
                                entityType: values.EntityType,
                                entityName: entityName,
                                userName: userName,
                                // nextRunDate: values.NextRunDate,
                                // reminder: values.Reminder,
                                weekday: weekDays,
                                id: values.Id
                            }

                            const MOBILESURETY_URL = config.API_URL + "/api/schedule/add"
                            axios.post(MOBILESURETY_URL, postData, {
                                headers: {
                                    'Authorization': `Basic ${token}`
                                }
                            })
                                .then((res) => {

                                    if (res.data.successCode === 0) {
                                        { id ? updateScheduleMessage() : addScheduleMessage() }
                                    } else {
                                        errorMessage()
                                    }
                                    setTimeout(() => { navigate("/scheduler") }, 3000)
                                })
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,

                        }) => (
                            <form onSubmit={handleSubmit}>
                                <ToastContainer theme='dark' />
                                <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} className="pt-5 pb-3">
                                        <TextField
                                            // fullWidth
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Every week(s)"
                                            helperText=""
                                            variant="standard"
                                            name="IntervalCount"
                                            value={values.IntervalCount || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {/* onChange={handleChange}> */}
                                            {weekList.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option == 1 ? (option + " Week") : (option + " Week(s)")}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.IntervalCount && touched.IntervalCount && errors.IntervalCount}</div>
                                    </Grid>
                                    {/* <span style={{ paddingLeft: "32px",justifyContent:"left", textAlign: "left" }} >Select Weekdays</span> */}
                                    <Grid item xs={12} sm={12} md={12} className="pt-0 py-4 pb-0">
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Monday"
                                            checked={weekDays.includes('Monday')}
                                            onChange={() => handleCheckboxChange('Monday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Monday" />
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Tuesday"
                                            checked={weekDays.includes('Tuesday')}
                                            onChange={() => handleCheckboxChange('Tuesday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Tuesday" />
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Wednesday"
                                            checked={weekDays.includes('Wednesday')}
                                            onChange={() => handleCheckboxChange('Wednesday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Wednesday" />
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Thursday"
                                            checked={weekDays.includes('Thursday')}
                                            onChange={() => handleCheckboxChange('Thursday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Thursday" />
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Friday"
                                            checked={weekDays.includes('Friday')}
                                            onChange={() => handleCheckboxChange('Friday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Friday" />
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Saturday"
                                            checked={weekDays.includes('Saturday')}
                                            onChange={() => handleCheckboxChange('Saturday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Saturday" />
                                        <FormControlLabel control={<Checkbox
                                            type="checkbox"
                                            value="Sunday"
                                            checked={weekDays.includes('Sunday')}
                                            onChange={() => handleCheckboxChange('Sunday')}
                                            style={{ color: "#F59300" }}
                                        />}
                                            label="Sunday" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker
                                                    label="Scheduled Time"
                                                    value={hour}
                                                    minutesStep="15"
                                                    onChange={(e) => { hour = e; setHour(hour); }}
                                                    slotProps={{ textField: { style: { width: "750px" } } }}
                                                    defaultValue={dayjs('2024-01-01T15:30:00')}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {/* <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="At Time"
                                            helperText=""
                                            variant="standard"
                                            name="Hour"
                                            value={hour || ''}
                                            onBlur={handleBlur}
                                            onChange={e => { hour = e.target.value; setHour(hour) }}
                                        >
                                            {options.map(option => (
                                                <MenuItem key={option.key} value={option.key}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        {/* <span className="errMsg">{errors.Region && touched.Region && errors.Region}</span>  */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            id="Date"
                                            label="Start Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="StartDate"
                                            value={values.StartDate}
                                            onChange={handleChange}
                                        />
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.StartDate && touched.StartDate && errors.StartDate}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Site"
                                            helperText=""
                                            variant="standard"
                                            name="SiteId"
                                            value={values.SiteId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {siteData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.projectName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.SiteId && touched.SiteId && errors.SiteId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Form"
                                            helperText=""
                                            variant="standard"
                                            name="FormId"
                                            value={values.FormId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {formData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.formSchemaName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.FormId && touched.FormId && errors.FormId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="User/Route"
                                            helperText=""
                                            variant="standard"
                                            name="EntityType"
                                            value={values.EntityType || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {entityType.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.EntityType && touched.EntityType && errors.EntityType}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select User/Group Name"
                                            helperText=""
                                            variant="standard"
                                            // name="EntityName"
                                            value={entity || ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => { setEntity(e.target.value); values.EntityType == "USER" ? setEntityName((userData.filter(u => u.id == e.target.value)[0].userName)) : setEntityName((routeData.filter(u => u.id == e.target.value)[0].routeName)) }}>
                                            {values.EntityType == "USER" ? userData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.userName}
                                                </MenuItem>
                                            )) : routeData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.routeName}
                                                </MenuItem>))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{(errors.EntityId && touched.EntityId && !entity) && errors.EntityId}</div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "100%" }}
                                            id="Date"
                                            label="Next Run Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="NextRunDate"
                                            value={values.NextRunDate}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField style={{ width: "100%" }} label="Reminder(Optional)" variant="standard" name="Reminder"
                                            value={values.Reminder || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                    </Grid> */}

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className="text-right mt-4 mx-3" style={{ paddingRight: "70px" }}>
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/scheduler")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" >
                                        Schedule
                                    </Button>
                                </Grid>
                                <br></br>
                            </form>
                        )
                        }
                    </Formik >
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            IntervalCount: rows?.intervalCount, StartDate: startDate, Hour: rows?.hour,
                            FormId: rows?.formId || formData.find(option => option.formSchemaName === "Route Service Report")?.id || '',
                            SiteId: rows?.siteId,
                            EntityId: rows?.entityId,
                            Id: rows?.id,
                            EntityType: rows?.entityType || 'USER',
                            NextRunDate: rows?.nextRunDate
                        }}
                        validate={values => {

                            const errors = {};
                            if (!values.IntervalCount) {
                                errors.IntervalCount = 'Required!';
                            }
                            if (!values.StartDate) {
                                errors.StartDate = 'Required!'
                            }
                            if (!values.SiteId) {
                                errors.SiteId = 'Required!'
                            }
                            if (!values.FormId) {
                                errors.FormId = 'Required!'
                            }
                            if (!values.EntityType) {
                                errors.EntityType = 'Required!'
                            }
                            if (!entity) {
                                errors.EntityId = "Required!"
                            }

                            return errors;
                        }}

                        const handleMouseDownPwd={(event) => {
                            event.preventDefault();
                        }}

                        onSubmit={(values, { setSubmitting }) => {
                            const postData = {
                                interval: "Monthly",
                                intervalCount: values.IntervalCount,
                                startDate: values.StartDate,
                                scheduleTime: hour.$H.toString().padStart(2, '0') + ":" + hour.$m.toString().padStart(2, '0') + ":00",
                                // hour: hour,
                                minute: 0,
                                formId: values.FormId,
                                siteId: values.SiteId,
                                entityId: entity,
                                entityType: values.EntityType,
                                entityName: entityName,
                                userName: userName,
                                id: values.Id
                                // nextRunDate: values.NextRunDate,
                                // reminder: values.Reminder
                            }

                            const MOBILESURETY_URL = config.API_URL + "/api/schedule/add"
                            axios.post(MOBILESURETY_URL, postData, {
                                headers: {
                                    'Authorization': `Basic ${token}`
                                }
                            })
                                .then((res) => {

                                    if (res.data.successCode === 0) {
                                        { id ? updateScheduleMessage() : addScheduleMessage() }
                                    } else {
                                        errorMessage()
                                    }
                                    setTimeout(() => { navigate("/scheduler") }, 3000)
                                })
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,

                        }) => (
                            <form onSubmit={handleSubmit}>
                                <ToastContainer theme='dark' />
                                <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} className="pt-5">
                                        <TextField
                                            // fullWidth
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Every Month(s)"
                                            helperText=""
                                            variant="standard"
                                            name="IntervalCount"
                                            value={values.IntervalCount || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {/* onChange={handleChange}> */}
                                            {monthList.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.IntervalCount && touched.IntervalCount && errors.IntervalCount}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker
                                                    label="Scheduled Time"
                                                    value={hour}
                                                    minutesStep="15"
                                                    onChange={(e) => { hour = e; setHour(hour) }}
                                                    slotProps={{ textField: { style: { width: "750px" } } }}
                                                    defaultValue={dayjs('2024-01-01T15:30:00')}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {/* <TextField
                                            style={{ width: "95%" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="At Time"
                                            helperText=""
                                            variant="standard"
                                            name="Hour"
                                            value={hour || ''}
                                            onBlur={handleBlur}
                                            onChange={e => { hour = e.target.value; setHour(hour) }}
                                        >
                                            {options.map(option => (
                                                <MenuItem key={option.key} value={option.key}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        {/* <span className="errMsg">{errors.Region && touched.Region && errors.Region}</span>  */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            id="Date"
                                            label="Start Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="StartDate"
                                            variant="standard"
                                            value={values.StartDate}
                                            onChange={handleChange}
                                        />
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.StartDate && touched.StartDate && errors.StartDate}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Site"
                                            helperText=""
                                            variant="standard"
                                            name="SiteId"
                                            value={values.SiteId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {siteData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.projectName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '7px' }}>{errors.SiteId && touched.SiteId && errors.SiteId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select Form"
                                            helperText=""
                                            variant="standard"
                                            name="FormId"
                                            value={values.FormId || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {formData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.formSchemaName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg">{errors.FormId && touched.FormId && errors.FormId}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="User/Route"
                                            helperText=""
                                            variant="standard"
                                            name="EntityType"
                                            value={values.EntityType || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {entityType.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div className="errMsg">{errors.EntityType && touched.EntityType && errors.EntityType}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "750px" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="Select User/Group Name"
                                            helperText=""
                                            variant="standard"
                                            // name="EntityName"
                                            value={entity || ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => { setEntity(e.target.value); values.EntityType == "USER" ? setEntityName((userData.filter(u => u.id == e.target.value)[0].userName)) : setEntityName((routeData.filter(u => u.id == e.target.value)[0].routeName)) }}>
                                            {values.EntityType == "USER" ? userData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.userName}
                                                </MenuItem>
                                            )) : routeData.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.routeName}
                                                </MenuItem>))}
                                        </TextField>
                                        <div className="errMsg" style={{ marginTop: '5px' }}>{(errors.EntityId && touched.EntityId && !entity) && errors.EntityId}</div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField
                                            style={{ width: "100%" }}
                                            id="Date"
                                            label="Next Run Date"
                                            type="date"
                                            color="primary"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            name="NextRunDate"
                                            value={values.NextRunDate}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} className="pt-3">
                                        <TextField style={{ width: "100%" }} label="Reminder(Optional)" variant="standard" name="Reminder"
                                            value={values.Reminder || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange} />
                                    </Grid> */}

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className="text-right mt-4 mx-3" style={{ paddingRight: "70px" }}>
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/scheduler")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" >
                                        Schedule
                                    </Button>
                                </Grid>
                                <br></br>
                            </form>
                        )
                        }
                    </Formik >
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <div className={classes.App}>
                        <ToastContainer theme='dark' />
                        <Typography style={{ fontWeight: 'bold', fontSize: '2rem' }}>Scheduler Upload</Typography>
                        <div className={classes.container}>
                            <div
                                className={classes.dropContainer}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                {selectedFiles.length > 0 ? (
                                    <div className="selected-file">{selectedFiles[0].name}</div>
                                ) : (
                                    'Drag and Drop CSV File or Click to Upload'
                                )}
                            </div>
                            <div style={{ marginBottom: '1rem' }}></div>
                            <div>
                                <Button type="submit" variant="contained" color="primary" className="no-shadow addBtn" onClick={handleParse}>
                                    Submit
                                </Button>
                            </div>
                            <div style={{ marginTop: "3rem" }}>
                                {loading && (
                                    <div className="loading-spinner" style={{ marginTop: "1rem" }}>
                                        <ClipLoader size={35} color={'#F59300'} loading={loading} />
                                    </div>
                                )}
                                {rows.length > 0 && (
                                    <TableContainer>
                                        <Table className={classes.table}
                                            aria-labelledby="tableTitle"
                                            aria-label="enhanced table">
                                            <EnhancedTableHead
                                                classes={classes}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {stableSort(filteredRows, getComparator(order, orderBy))
                                                    .map((filteredRows, index) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell>{filteredRows.refNumber}</TableCell>
                                                                <TableCell>{filteredRows.errorMsg}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </div>
                        </div>
                    </div>
                </CustomTabPanel>
            </Box>

        </div>
    );
}

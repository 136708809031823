import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from '@mui/styles';
import MuiTheme from './theme';
import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import { Loader } from './shared/components/Loader';
import Dashboard from './pages/Dashboard';
import UserList from './pages/Users/Users/UserList';
import AddUser from './pages/Users/AddUser';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Site from './pages/Site/Site/Site';
import AddEditSite from './pages/Site/AddEditSite/AddEditSite';
import Part from './pages/Part/Part/Part';
import AddEditPart from './pages/Part/AddEditPart/AddEditPart';
import Scheduler from './pages/Scheduler/Scheduler/Scheduler';
import RouteServiceReport from './pages/RouteServiceReport/RouteServiceReport';
import ManagerServiceReport from './pages/ManagerServiceReport/ManagerServiceReport';
import TreeTrimmingProposal from './pages/TreeTrimmingProposal/TreeTrimmingProposal';
import AddRoute from './pages/Route/AddRoute/AddRoute';
import RouteComponent from './pages/Route/Route/RouteUsers';
import AddScheduler from './pages/Scheduler/AddScheduler/AddScheduler';
import Dispatches from './pages/Dispatches/Dispatches/Dispatches';
import TaskList from './pages/Site/TaskList/TaskList';
import EditDispatch from './pages/Dispatches/EditDispatch/EditDispatch';
import Build from './pages/Build/Build';


const Login = lazy(() => import('./pages/Login'));
const RoutesComponent = () => {

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                <Loader color="#ff9900" />
              </div>
            </div>
          }>
          <PresentationLayout>
            {/* login */}
          </PresentationLayout>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="/resetpwd/:pwdkey" element={<ForgotPassword />}></Route>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route element={<LeftSidebar />}>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/user/list" element={<UserList />}></Route>
              <Route path="/user/add" element={<AddUser />}></Route>
              <Route path="/user/edit/:id" element={<AddUser />}></Route>
              <Route path="/user/reset-password" element={<ResetPassword />}></Route>
              <Route path="/sites/list" element={<Site />}></Route>
              <Route path="/sites/add" element={<AddEditSite />}></Route>
              <Route path="/sites/edit/:id" element={<AddEditSite />}></Route>
              <Route path="/part/list" element={<Part />}></Route>
              <Route path="/part/add" element={<AddEditPart />}></Route>
              <Route path="/part/edit/:id" element={<AddEditPart />}></Route>
              <Route path="/scheduler" element={<Scheduler />}></Route>
              <Route path="/schedule/add" element={<AddScheduler />}></Route>
              <Route path="/schedule/edit/:id" element={<AddScheduler />}></Route>
              <Route path="/route/report" element={<RouteServiceReport />}></Route>
              <Route path="/manager/report" element={<ManagerServiceReport />}></Route>
              <Route path="/trimming/proposal" element={<TreeTrimmingProposal />}></Route>
              <Route path="/route/list" element={<RouteComponent />}></Route>
              <Route path="/route/add" element={<AddRoute />}></Route>
              <Route path="/route/edit/:id" element={<AddRoute />}></Route>
              <Route path="/dispatches/list/:id" element={<Dispatches />}></Route>
              <Route path="/dispatches/edit/:id" element={<EditDispatch />}></Route>
              <Route path="/sites/task" element={<TaskList />}></Route>
              <Route path="/release/list" element={<Build />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default RoutesComponent;
